import { useEffect } from "react";
import animationBlob from "../../Assets/blob-bg.png";
// import AboutMe from "../AboutMe";
import MyThree from "./MyThree";

export default function AnimationBlob() {
    return (
        <div className="w-full flex flex-col items-center">
            <div className="md:w-9/12 md:-mt-[5vh] flex justify-center items-center">
                <div className="relative w-full mt-20 pt-20 md:my-0 md:pt-0">
                    <img
                        src={animationBlob}
                        className="w-full z-0 ml-[3%]"
                        alt="Animation Blob"
                    />
                    {/* <MyThree /> */}
                    {/* here i want the blob to be in the center */}
                    {/* <div id="content">
                        <audio id="audio" className="hidden" econtrols></audio>
                        <div id="out"></div>
                    </div> */}
                    <iframe
                        title="blob"
                        className="w-[40%] md:w-[33%] left-[30vw] md:left-[26vw] absolute bottom-[0.1rem] md:bottom-[10%] z-20"
                        src="https://my.spline.design/untitledcopy-892aec6ddd4c393192eadbcb7c2ca04e/"
                        width="100%"
                        height="100%"
                        id="myframe1"
                    ></iframe>
                    <div className="w-full right-0 absolute bottom-[12%] md:h-[3.5vmax] bg-[#15151A] z-30"></div>
                </div>
            </div>
        </div>
    );
}
