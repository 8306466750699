import { useScroll } from "framer-motion";
import { useEffect, useState } from "react";
import { BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";

export default function SocialMediaNavbar(props) {
    const { scrollYProgress } = useScroll();
    const [revealFactor, setRevealFactor] = useState(0);

    useEffect(() => {
        function updateOpacity() {
            setRevealFactor(scrollYProgress.current - 0.5);
            console.log(scrollYProgress.current - 0.5, " is something1");
        }
        const unsubscribeY = scrollYProgress.on("change", updateOpacity);
        return () => {
            unsubscribeY();
        };
    }, [scrollYProgress]);
    return (
        <div className="flex justify-start fixed left-[0vw] bottom-[20rem] z-40">
            <div className="hidden md:flex w-full relative flex-col md:px-28 justify-center items-center font-PoR">
                <a
                    className="text-[0.9rem] -rotate-90  pl-0 pr-0"
                    style={
                        revealFactor >= -0.27179442 &&
                        revealFactor <= -0.11051839
                            ? {
                                  color: "black",
                                  opacity: props.opacity,
                              }
                            : {
                                  color: "white",
                                  opacity: props.opacity,
                              }
                    }
                    href="https://www.instagram.com/_hribhav/"
                >
                    Instagram
                </a>
                <a
                    className=" -rotate-90 text-[0.9rem] py-28"
                    style={
                        revealFactor >= -0.2953407 &&
                        revealFactor <= -0.13422597
                            ? {
                                  color: "black",
                                  opacity: props.opacity,
                              }
                            : {
                                  color: "white",
                                  opacity: props.opacity,
                              }
                    }
                    href="https://www.linkedin.com/in/hribhav-panchal-5045551a7"
                >
                    LinkedIn
                </a>
                <a
                    className="-rotate-90 text-[0.9rem] pl-0 pr-0"
                    style={
                        revealFactor >= -0.30840408 &&
                        revealFactor <= -0.14777317
                            ? {
                                  color: "black",
                                  opacity: props.opacity,
                              }
                            : {
                                  color: "white",
                                  opacity: props.opacity,
                              }
                    }
                    href="https://twitter.com/HribhavP"
                >
                    Twitter
                </a>
            </div>
            <div className="fixed md:px-28 bottom-0">
                <img
                    id={props.id}
                    style={props.style}
                    src={props.image}
                    className={``+props.classname}
                    alt="Social Media Navbar"
                />
            </div>
            <div className="md:hidden text-white text-xl pl-4 flex flex-col justify-center items-center space-y-10">
                <div>
                    <a href="https://www.instagram.com/_hribhav/">
                        <BsInstagram />
                    </a>
                </div>
                <div>
                    <a href="https://www.linkedin.com/in/hribhav-panchal-5045551a7">
                        <BsLinkedin />
                    </a>
                </div>
                <div>
                    <a href="https://twitter.com/HribhavP">
                        <BsTwitter />
                    </a>
                </div>
                {/* <div className="mx-auto flex justify-center items-center">
                    <img
                        id={props.id}
                        style={{ width: "60px", height: "60px" }}
                        src={props.image}
                        className={props.classname}
                        alt="Social Media Navbar"
                    />
                </div> */}
            </div>
        </div>
    );
}
