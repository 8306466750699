import hribhav_name from "../../Assets/hribhav_name.png";
import hribhav_funky_photo from "../../Assets/hribhav_funky_photo.png";
import Navbar from "../../Components/Navbar";
import SocialMediaNavbar from "../../Components/SocialMediaNavbar";
import sanityClient from "../../sanity-client";
import { useEffect, useState } from "react";
import Pic from "../../Components/Pic";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default function NotWork() {
    const [data, setData] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "blog"] {
      images[] {
        asset -> {
          url
        }
      },
      link,
      cards[]
    }`
            )
            .then((data) => {
                console.log(data, " is data");
                setData(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div className="w-full min-h-screen  bg-[#15151A]">
            <Pic />
            <Navbar name={hribhav_name} width={"w-14"} paddingTop={"3rem"} />
            <SocialMediaNavbar
                id="unclicked"
                imageDisplay="block"
                display="block"
                image={hribhav_funky_photo}
                style={{
                    marginLeft: "0rem",
                    display: "block",
                    width: "30.333%",
                    cursor: "pointer",
                }}
            />
            <div className="container mx-auto py-28">
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                    <Masonry>
                        {data &&
                            data.map((item, index) => {
                                return (
                                    <div className="z-[100] flex flex-col justify-evenly m-3">
                                        <a
                                            href={item.link && item.link}
                                            className="my-5 md:my-0 p-5 flex flex-col justify-evenly w-full space-y-7 bg-[#292932] rounded-md"
                                        >
                                            <h1 className="text-xl md:text-3xl font-PoB pb-0 text-white">
                                                {item.cards && item.cards[0].heading}
                                            </h1>
                                            <p
                                                className="text-base md:text-lg font-PoM text-white"
                                                style={{
                                                    wordBreak: "break-word",
                                                    whiteSpace: "pre-wrap",
                                                }}
                                            >
                                                {item.cards && item.cards[0].content}
                                            </p>
                                            {item.images && (
                                                <img
                                                    src={
                                                        item.images[0].asset.url
                                                    }
                                                    alt=""
                                                    className="rounded-lg"
                                                    style={{
                                                        height: "auto",
                                                        maxWidth: "100%",
                                                    }}
                                                />
                                            )}
                                        </a>
                                    </div>
                                );
                            })}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </div>
    );
}
