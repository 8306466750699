import rozgaar_background from "../../Assets/rozgaar_background.png";
import rozgaar_phone_ui from "../../Assets/rozgaar_phone_ui.png";
import { useEffect, useState } from "react";
import { useTransform, motion, useScroll } from "framer-motion";

export default function Rozgaar(props) {
    const xposition1 = useTransform(props.x, [0, 5000], [-40, 100]);
    const yposition1 = useTransform(props.y, [0, 5000], [-100, 20]);
    const [windowSize, setWindowSize] = useState(0);
    useEffect(() => {
        setWindowSize(window.innerWidth);
    }, []);
    const { scrollYProgress } = useScroll();
    const [revealFactor, setRevealFactor] = useState(0);

    useEffect(() => {
        function updateOpacity() {
            setRevealFactor(scrollYProgress.current - 0.5);
            console.log(scrollYProgress.current - 0.5, " is something");
        }

        const unsubscribeY = scrollYProgress.on("change", updateOpacity);
        // const unsubscribeY = y.on("change", updateOpacity)

        return () => {
            // unsubscribeX()
            unsubscribeY();
        };
        // return scrollYProgress.onChange(() => {
        //   setRevealFactor(scrollYProgress.current - 0.5)
        //   console.log(scrollYProgress.current - 0.5, ' is something')
        // })
    }, [scrollYProgress]);
    return (
        <div
            className="md:px-32 w-full sticky top-[1vw] min-h-screen md:h-[110vh] flex flex-col md:flex-row justify-center items-center"
            style={
                {
                    // zIndex: `${(revealFactor > 0.101532) ? 21 : 19}`,
                    // filter: `blur(${(((revealFactor - 0.115)<0)?((revealFactor-0.115)*(-200)):((revealFactor-0.145>0)?((revealFactor-0.145)*50):0))}rem)`,
                }
            }
        >
            <div className="absolute w-full z-0">
                <img
                    src={rozgaar_background}
                    className="w-full min-h-screen"
                    alt="memboro_background"
                />
            </div>
            <div className="md:w-[80%] flex flex-col md:flex-row justify-center -mr-[5vmax]">
                <div className="md:w-6/12 flex flex-col justify-center z-30 -mr-[9vmax]">
                    <div className="w-full -mt-48 md:-mt-0 pl-14 md:pl-0">
                        <p className="text-white w-full font-KaB tracking-wide text-[44px] px-4 md:px-0">
                            Rozgaar
                        </p>
                        <div className="flex md:hidden">
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                        <p className="hidden md:flex flex-col text-white w-8/12 font-Sgr tracking-light text-[16px] pt-0">
                            An augmented reality application that enhances your
                            experience of a destination using cumulative
                            extended user experiences{" "}
                        </p>
                        <a className="text-white" href="https://www.behance.net/gallery/169358881/Rozgaar-Blue-Collar-Jobs" target="_blank" rel="noreferrer">Click Here →</a>
                        <p className="text-white md:w-1/2 font-PoB text-base pt-10 pb-1.5 px-4 md:px-0">
                            My Role
                        </p>
                        <p className="text-sm text-white md:w-1/2 font-PoR px-4 md:px-0">
                            UX Designer | Unity Developer
                        </p>
                        <div className="flex w-2/3 md:w-10/12 px-4 md:px-0 pt-10 justify-start flex-wrap">
                            <div className="font-PoM flex z-20 bg-white min-w-fit text-[#EB1934] text-sm px-5 mr-3 py-1 my-1 rounded-lg items-center justify-center">
                                NLP & DIP
                            </div>
                            <div className="font-PoM flex z-20 bg-white min-w-fit text-[#EB1934] text-sm px-6 mr-3 py-1 my-1  rounded-lg items-center justify-center">
                                Startup
                            </div>
                            <div className="font-PoM flex z-20 bg-black min-w-fit text-white text-sm px-7 py-1 my-1 rounded-lg items-center justify-center">
                                Miro
                            </div>
                        </div>
                        <div className="flex w-2/3 md:w-full px-4 md:px-0 py-1 justify-start flex-wrap">
                            <div className="font-PoM flex z-20 my-1 bg-white min-w-fit text-[#EB1934] text-sm px-5 mr-3 py-1 rounded-lg items-center justify-center">
                                Blue Collar Jobs
                            </div>
                            <div className="font-PoM flex z-20 my-1 bg-black min-w-fit text-white text-sm px-5 mr-3 py-1 rounded-lg items-center justify-center">
                                Notion
                            </div>
                            <div className="font-PoM flex z-20 my-1 bg-black min-w-fit text-white text-sm px-5 mr-3 py-1 rounded-lg items-center justify-center">
                                Figma
                            </div>
                            <div className="font-PoM flex z-20 my-1 bg-black min-w-fit text-white text-sm px-5 py-1 rounded-lg items-center justify-center">
                                Travel
                            </div>
                        </div>
                    </div>
                </div>
                <motion.div
                    className="w-6/12 mx-auto z-20 flex items-center -mt-[450px] md:-mt-0"
                    style={{
                        translateX: windowSize > 1024 ? xposition1 : 0,
                        translateY: windowSize > 1024 ? yposition1 : 0,
                    }}
                >
                    <img
                        src={rozgaar_phone_ui}
                        alt="Rozgaar Phone UI"
                        className="w-full"
                    />
                </motion.div>
            </div>
        </div>
    );
}
