import { useScroll } from "framer-motion";
import { useEffect, useState } from "react";
import hribhav_name_black from "../../Assets/hribhav_name_black.png";

export default function Navbar(props) {
    const { scrollYProgress } = useScroll();
    const [revealFactor, setRevealFactor] = useState(0);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        function updateOpacity() {
            setRevealFactor(scrollYProgress.current - 0.5);
            console.log(scrollYProgress.current - 0.5, " is something1");
        }
        const unsubscribeY = scrollYProgress.on("change", updateOpacity);
        return () => {
            unsubscribeY();
        };
    }, [scrollYProgress]);
    return (
        <>
            <div
                className="flex w-full z-[50] items-end justify-between bg-transparent fixed"
                style={
                    revealFactor >= -0.18761 && revealFactor <= -0.0263323
                        ? {
                              paddingTop: props.paddingTop,
                              color: "black",
                          }
                        : {
                              paddingTop: props.paddingTop,
                              color: "white",
                          }
                }
            >
                <div className="hidden md:flex w-full px-36 items-end justify-between">
                    <div className="flex items-center justify-center">
                        <a href="/">
                            <img
                                src={props.name}
                                className={props.width}
                                alt="hribhav_signature"
                            />
                        </a>
                    </div>
                    <div className="flex items-center justify-center text-[0.9rem] font-PoR">
                        <a
                            className="my-0 px-6 cursor-pointer font-light"
                            href="/#my-work"
                        >
                            My Work
                        </a>
                        <a
                            className="my-0 px-6 cursor-pointer font-light"
                            href="/not_work"
                        >
                            My Not Work
                        </a>
                        <a
                            className="my-0 px-6 cursor-pointer font-light"
                            href="/about"
                        >
                            About Me
                        </a>
                        <a
                            className="my-0 pl-6 cursor-pointer font-light"
                            href="https://drive.google.com/file/d/1HrP5BVAyR6BH_0UW9BuuV0p8asdCHpkh/view?usp=sharing"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Resume
                        </a>
                    </div>
                </div>
                <div className="flex md:hidden w-full px-12 items-end justify-between">
                    <div className="flex items-center justify-center">
                        <img
                            src={props.name}
                            className={props.width}
                            alt="hribhav_signature"
                        />
                    </div>
                    <div className="flex items-center justify-center text-[0.8rem] font-PoR">
                        <button
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            ☰
                        </button>
                    </div>
                </div>
            </div>
            {open && (
                <>
                    <div className="bg-white min-h-screen z-[100] space-y-[500px] py-12 w-full">
                        <div className="flex w-full px-12 items-end justify-between">
                            <div className="flex items-center justify-center">
                                <img
                                    src={hribhav_name_black}
                                    className={props.width}
                                    alt="hribhav_signature"
                                />
                            </div>
                            <div className="flex items-center justify-center text-xl text-black font-PoR">
                                <button
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    x
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col px-6 text-left text-[2rem] font-Ga">
                                <a
                                    className="my-0 px-6 cursor-pointer"
                                    href="/"
                                >
                                    My Work
                                </a>
                                <a
                                    className="my-0 px-6 cursor-pointer"
                                    href="/not_work"
                                >
                                    My Not Work
                                </a>
                                <a
                                    className="my-0 px-6 cursor-pointer"
                                    href="/about"
                                >
                                    About Me
                                </a>
                                <a
                                    className="my-0 pl-6 cursor-pointer"
                                    href="/resume"
                                >
                                    Resume
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
