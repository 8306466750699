import music_mute from "../../Assets/music_muteWhite.png";
import music_unmute from "../../Assets/music_unmuteWhite.png";
import scrollWhite from "../../Assets/scrollWhite.png";
import scrollBlack from "../../Assets/scrollBlack.png";
import { useEffect, useRef, useState } from "react";
// import ReactPlayer from "react-player";
// import music from "./music-moonshine.mp3";
import { useScroll } from "framer-motion";

export default function MusicAndScrollControlBar(props) {
    const [playing, setPlaying] = useState(false);
    const { scrollYProgress } = useScroll();
    const [revealFactor, setRevealFactor] = useState(0);
    // const audio = useRef(null);

    console.log(playing);

    useEffect(() => {
        const audio = document.getElementById("my-audio");
        audio.volume = 0.3;
        setPlaying(false);
    }, []);

    useEffect(() => {
        function updateOpacity() {
            setRevealFactor(scrollYProgress.current - 0.5);
            console.log(scrollYProgress.current - 0.5, " is any");
        }
        const unsubscribeY = scrollYProgress.on("change", updateOpacity);
        return () => {
            unsubscribeY();
        };
    }, [scrollYProgress]);

    const handlePlaying = () => {
        const audio = document.getElementById("my-audio");
        if (audio.paused || audio.ended) {
            audio.play();
            setPlaying(true);
        } else {
            audio.pause();
            setPlaying(false);
        }
    };

    // const handleAudioLoad = () => {
    //     handlePlaying();
    // };

    return (
        <div className="flex h-full right-[7vw] bottom-[20rem] fixed z-40">
            <audio
                id="my-audio"
                className="hidden"
                src="/music-moonshine.mp3"
                controls
                // autoPlay
                loop
                // onLoadedMetadata={handleAudioLoad}
                muted={playing ? false : true}
            >
                <source src="/music-moonshine.mp3" type="audio/mp3" />
                <p>Your browser does not support the audio element</p>
            </audio>
            <div className="w-full p-0 -mt-8 flex flex-col justify-end items-end text-white font-PoR">
                <button onClick={() => handlePlaying()} className="my-5 -mr-1">
                    {playing ? (
                        <img
                            className="w-[2rem] py-2"
                            alt="music"
                            src={music_unmute}
                        />
                    ) : (
                        <img
                            className="w-[2rem] py-2"
                            alt="music"
                            src={music_mute}
                        />
                    )}
                </button>

                <img
                    className="w-[1.5rem] pr-0"
                    src={
                        revealFactor >= -0.264388233 &&
                        revealFactor <= -0.10757535
                            ? scrollBlack
                            : scrollWhite
                    }
                    alt="scroll"
                />
                <p
                    className="font-PoR text-sm rotate-90 m-0 mt-16 -mr-7"
                    style={
                        revealFactor >= -0.2887185 &&
                        revealFactor <= -0.13190568
                            ? {
                                  color: "black",
                              }
                            : {
                                  color: "white",
                              }
                    }
                >
                    Scroll Down
                </p>
            </div>
            <div className="text-white fixed right-[8vw] bottom-[2vh] z-40 text-right">
                <p>
                    A <b>UX designer</b>
                </p>
                <p>who asks a lot of</p>
                <p>
                    questions, mostly '<b>WHY</b>?'
                </p>
            </div>
        </div>
    );
}
